import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const ErrorIcon = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  >
    <path
      stroke="#CD2026"
      strokeWidth="2"
      d="M21 15.728L15.728 21H8.272L3 15.728V8.272L8.272 3h7.456L21 8.272v7.456zM12 7v6M12 15v2"
    ></path>
  </motion.svg>
);

export default ErrorIcon;
